import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2";

export default class extends Controller {
  // Mandar a notificação do relatório
  notificateReport(element) {
    Swal.fire({
      title: element.params.currentAdminUser,
      text: 'Fique na página até que o download seja concluido',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#007bff'
    })
  }
}
