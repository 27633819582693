import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["documentsSelected"]

  handleNeedsDocuemnts(element) {
    const { checked } = element.target

    if (checked) {
      this.documentsSelectedTarget.classList.remove('d-none')
    } else {
      this.documentsSelectedTarget.classList.add('d-none')
    }
  }
}
