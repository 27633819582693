import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  inputTargetConnected(element) {
    element.setAttribute('data-toggle', "toggle")
    element.setAttribute('data-size', "xs")
    element.setAttribute('data-on', " ")
    element.setAttribute('data-off', " ")
    element.setAttribute('data-onstyle', "success")
    element.setAttribute('data-offstyle', "danger")
  }
}
