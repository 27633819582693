import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resultFilter", "loading"];

  connect() { }

  onResultFilter(event) {
    let [data, status, xhr] = event.detail;
    this.resultFilterTarget.innerHTML = xhr.response;
  }

  onLoading(event) {
    this.loadingTarget.classList.toggle("d-none");
  }

  onCloseModal(event) {
    this.resultFilterTarget.innerHTML = `<div class="alert alert-info"><span>Use os filtro para que as informações</span></div>`;
  }
}
