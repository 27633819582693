// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  openModalDistanceFee(event) {
    this.#openModal({
      modalTarget: "#modalDistanceFees",
      modalContent: "#levoo-admin-form-distance-fee-content",
      modalSync: "#levoo-admin-form-distance-fee-content-sync",
    });
  }
  openModalContractAuthorization(event) {
    this.#openModal({
      modalTarget: "#modalPermissions",
      modalContent: "#levoo-admin-form-permissions-content",
      modalSync: "#levoo-admin-form-permissions-content-sync",
    });
  }

  #openModal({ modalTarget, modalContent, modalSync }) {
    document
      .querySelectorAll(`a[data-target="${modalTarget}"]`)
      .forEach((item) => {
        item.addEventListener("ajax:before", () => {
          document
            .querySelector(`${modalTarget} ${modalSync}`)
            .classList.remove("d-none");
          document.querySelector(`${modalTarget} ${modalContent}`).innerHTML =
            "";
        });
      });
  }
  delivererDiscountPercentageSelectedFunction(event) {
    const calculate_new_deliverer_payment = document.querySelector("#calculate_new_deliverer_payment");
    const deliverer_discount = document.querySelector("#deliverer_discount");

    if (calculate_new_deliverer_payment.checked === true) {
      deliverer_discount.classList.remove("d-none");
      deliverer_discount.value = 0.00;
    } 
    else {
      deliverer_discount.value = "";
      deliverer_discount.classList.add("d-none");
    }
  }

}
