import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["cepMask", "phoneMask", "zipcodeMask", "cnpjMask", "cpfMask", "dateMask", "cpfAndCnpjMask", "yaerDateMask"];

  cepMaskTargetConnected(element) {
    this.applyMask(element, 'cep-mask', '99999-999')
  }

  phoneMaskTargetConnected(element) {
    this.applyMask(element, 'phone-mask', '(99) 99999-9999')
  }

  zipcodeMaskTargetConnected(element) {
    this.applyMask(element, 'zipcode-mask', '99999-999')
  }

  cnpjMaskTargetConnected(element) {
    this.applyMask(element, 'cnpj-mask', '99.999.999/9999-99')
  }

  cpfMaskTargetConnected(element) {
    this.applyMask(element, 'cpf-mask', '999.999.999-99')
  }

  cpfAndCnpjMaskTargetConnected(element) {
    this.applyMask(element, 'cpf-and-cnpj-mask', ['999.999.999-99', '99.999.999/9999-99'])
  }

  dateMaskTargetConnected(element) {
    this.applyMask(element, 'date-mask', '99/99/9999', false)
  }

  yaerDateMaskTargetConnected(element) {
    this.applyMask(element, 'yaer-date-mask', '9999')
  }

  applyMask(target, element, mask, removeSubmit = true) {
    target.classList.add(element)

    Inputmask({
      mask: mask,
      removeMaskOnSubmit: removeSubmit,
    }).mask($(`.${element}`));
  }
}
