import { Controller } from "@hotwired/stimulus";
import "chosen-js";
import Swal from "sweetalert2";

const deliverer = {};

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  toggleBlocked(event) {
    const company_id = document.querySelector("#company_id").value;
    const all_blocked = document.querySelector("#all_blocked").checked;
    const reason = document.querySelector("#reason").value;

    const delivererId = deliverer.id ? deliverer.id : event.target.id;
    const action = deliverer.action ? deliverer.action : event.params.action;
    const text = action === "Bloquear" ? "bloqueado" : "desbloqueado";

    const url = `/administration/deliverers/${delivererId}/toggle_blocked`;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: delivererId,
        reason: reason,
        company_id: all_blocked ? -1 : company_id,
        blocked: action === "Bloquear" ? true : false,
      }),
    };

    Swal.fire({
      title: `Entregador sendo ${text}`,
      text: "por favor, aguarde...",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        new Swal({
          text: `Entregador ${text} com sucesso`,
          icon: "success",
        });
      })
      .catch((error) => {
        new Swal({ text: error.responseJSON.message, icon: "error" });
      });
  }

  selectDeliverer(event) {
    deliverer.id = event.params.delivererId
      ? event.params.delivererId
      : event.target.id;
    deliverer.action = event.params.action;
  }

  allBlockedChange() {
    const company_box = document.querySelector("#company-box");
    company_box.classList.toggle("d-none");
    company_box.classList.toggle("d-flex");
  }
}
