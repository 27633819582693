import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['inputDelivererDiscount', 'inputNewDelivererPayment']

  inputDelivererDiscountTargetConnected() {
    this.handleDiscountPercentage()
  }

  handleDiscountPercentage(element) {
    if (this.inputNewDelivererPaymentTarget.checked === true) {
      this.inputDelivererDiscountTarget.classList.remove("d-none");
      this.inputDelivererDiscountTarget
        .querySelector('input[name="contract[deliverer_discount]"][type="number"]').value = '0';
    }
    else {
      this.inputDelivererDiscountTarget
        .querySelector('input[name="contract[deliverer_discount]"][type="number"]').value = "";
      this.inputDelivererDiscountTarget.classList.add("d-none");
    }
  }
}
