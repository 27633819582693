/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Estilos css usando pré-processadores sass
import "styles/application";

import "bootstrap";
import "bootstrap4-toggle/js/bootstrap4-toggle.min.js";

import "@fortawesome/fontawesome-free/js/all";

import "jquery";
import "jquery-ui";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";

const nativeConfirm = Rails.confirm;
let __SkipConfirmation = false;

// TODO: para saber mais veja https://derk-jan.com/articles/2020/10/rails-ujs-custom-confirm/
Rails.confirm = (message, element) => {
  if (__SkipConfirmation) {
    return true;
  }

  if (!element.hasAttribute('data-sweet-alert')) {
    return nativeConfirm(message, element);
  }

  function showDialog(element, onConfirm) {
    const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}');
    const message = element.getAttribute('data-confirm');

    Swal.fire({
      title: 'Are you sure?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      ...options,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  }

  function onConfirm() {
    __SkipConfirmation = true;
    element.click();
    __SkipConfirmation = false;
  }

  showDialog(element, onConfirm);
}

Rails.start();
Turbolinks.start();

import Swal from "sweetalert2";
import "admin-lte";

import toastr from "toastr";
window.toastr = toastr;

toastr.options = {
  closeButton: true,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const images = require.context("images", true);
const imagePath = (name) => images(name, true);

if (process.env.NODE_ENV === "development") {
  require("dotenv").config();
}

import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-http';
import 'prismjs/themes/prism-tomorrow.css';

import "controllers";
