import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import "chosen-js";

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  handleFinishDelivery() {
    const delivery_id = document.querySelector("#delivery_id").value;
    const url = `/administration/v2/deliveries/${delivery_id}/finish`;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    new Swal({
      title: "Você tem certeza?",
      text: "Tem certeza que deseja finalizar a corrida?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(
      (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Finalizando corrida",
            didOpen: () => {
              Swal.showLoading();
            },
          });
          fetch(url, requestOptions)
            .then((resp) => resp.json())
            .then((data) => {
              new Swal({ text: data.message, type: "success" });
            })
            .catch((error) => {
              new Swal({ text: error.responseJSON.message, type: "error" });
            });
        }
      },
      (dismiss) => true
    );
  }
}
