import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loaderContainer"];

  connect() { }

  // Chamar antes de uma requisição
  onStart(event) {
    this.loaderContainerTarget.classList.remove("d-none");
    document.querySelector("html").classList.add("loader-overflow");
  }

  // Chamar quando receber uma requisição com sucesso
  onFinish(event) {
    this.loaderContainerTarget.classList.add("d-none");
    document.querySelector("html").classList.remove("loader-overflow");
  }
}
