// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  open(event) {
    this.#openModal({
      modalTarget: "#modalDeliverersNear",
      modalContent: "#levoo-admin-table-deliverers-near-content",
      modalSync: "#levoo-admin-table-deliverers-near-content-sync",
    });
  }

  #openModal({ modalTarget, modalContent, modalSync }) {
    document
      .querySelectorAll(`a[data-target="${modalTarget}"]`)
      .forEach((item) => {
        item.addEventListener("ajax:before", () => {
          document
            .querySelector(`${modalTarget} ${modalSync}`)
            .classList.remove("d-none");
          document.querySelector(`${modalTarget} ${modalContent}`).innerHTML =
            "";
        });
      });
  }
}
