import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import GoogleMaps from "./google_maps_controller";

const customer = {};
const current_customer = {};
export default class extends Controller {
  static targets = ["output"];

  connect() {
    Inputmask({
      mask: "(99) 99999-9999",
      removeMaskOnSubmit: true,
    }).mask($(".phone-mask"));

    Inputmask({
      mask: "999.999.999-99",
      removeMaskOnSubmit: true,
    }).mask($(".cpf-mask"));

    Inputmask({
      mask: "99.999.999/9999-99",
      removeMaskOnSubmit: true,
    }).mask($(".cnpj-mask"));

    Inputmask({
      mask: "99/99/9999",
    }).mask($(".date-mask"));

    Inputmask({
      mask: "99999-999",
    }).mask($(".cep-mask"));
  }

  submit() {
    const contract_id = document.querySelector("#contract_id");
    const phone = document.querySelector("#customer_phone");
    const items_description = document.querySelector(
      "#customer_items_description",
    );

    const url = `/administration/company/customers/new_delivery`;
    const params = {
      customer: {
        phone: phone.value.replace(/\D/g, ""),
        items_description: items_description.value,
        contract_id: contract_id.value,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const validate = this.validate([contract_id, phone, items_description]);

    if (!validate) {
      return;
    }

    Swal.fire({
      title: "Criando corrida",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        new Swal({});
        Swal.fire({
          title: "Corrida criada com sucesso!",
          icon: "success",
          showDenyButton: true,
          confirmButtonText: "Visualizar corrida",
          denyButtonText: "Criar nova corrida",
          denyButtonColor: "#28a745",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = `/administration/deliveries/${resp.id}`;
            Swal.fire({
              position: "center",
              text: "Redirecionando...",
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
          } else if (result.isDenied) {
            this.clearInputs();
            phone.value = "";
            this.routeCard();
          }
        });
      })
      .catch((error) => {
        new Swal({
          icon: "error",
          title: "Erro ao cadastrar cliente!",
          text: error,
        });
      });
  }

  async customerRegister(event) {
    const request_type = event.params.type;

    const phone = document.querySelector("#customer_phone");
    const inputs = document.querySelectorAll(
      `#customer_phone, #first_name_destiny, #last_name_destiny, #customer_cep, #customer_neighborhood, #customer_street, #customer_number, #customer_complement`,
    );
    const city_state = document.querySelectorAll(
      "#customer_state, #customer_city",
    );

    const url =
      request_type === "POST"
        ? "/administration/company/customers.json"
        : `/administration/company/customers/${current_customer.customer.id}`;

    const body = {
      customer: {
        first_name: inputs[1].value,
        last_name: inputs[2].value,
        phone: inputs[0].value.replace(/\D/g, ""),
        address_attributes: {
          zipcode: inputs[3].value.replace(/\D/g, ""),
          number: inputs[6].value,
          complement: inputs[7].value,
          neighborhood: inputs[4].value,
          street: inputs[5].value,
        },
      },
    };
    const address = `${body.customer.address_attributes.number} ${body.customer.address_attributes.street} ${body.customer.address_attributes.neighborhood},${body.customer.address_attributes.city} ${body.customer.address_attributes.state}, Brasil`;
    await this.getCityID(city_state[1].value, city_state[0].value);
    await this.latLng(address);

    body.customer.address_attributes.lat = customer.lat_lng.lat;
    body.customer.address_attributes.lng = customer.lat_lng.lng;
    body.customer.address_attributes.city_id = customer.city_id;

    if (request_type === "PUT") {
      body.customer.address_attributes.id = current_customer.address.id;
    }

    const validate = this.validate(inputs);

    if (!validate) {
      return;
    }

    const requestOptions = {
      method: request_type === "POST" ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };

    Swal.fire({
      title:
        request_type === "POST" ? "Cadastrando cliente" : "Atualizando cliente",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const get_customer_by_phone_param = {
      target: phone,
    };
    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        new Swal({
          icon: "success",
          title:
            request_type === "POST"
              ? "Cliente cadastrado com sucesso!"
              : "Cliente atualizado com sucesso!",
        });
      })
      .then((resp) => {
        this.getCustomerByPhone(get_customer_by_phone_param);
      })
      .catch((error) => {
        new Swal({
          icon: "error",
          title: "Erro ao cadastrar cliente!",
          text: error,
        });

        return;
      });
  }

  validate(inputs) {
    let validated = true;

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        const error = document.querySelector(`#${input.name}-error`);
        error && error.remove();

        const p = document.createElement("p");
        p.id = `${input.name}-error`;
        p.classList.add("text-danger", "font-weight-bold");
        input.classList.add("border-danger");
        input.parentElement.appendChild(p);
        p.innerText = "Esse campo é obrigatório!";

        validated = false;
      } else {
        const error_element = document.querySelector(`#${input.name}-error`);
        error_element && error_element.classList.add("d-none");
        input.classList.remove("border-danger");
      }
    });

    return validated;
  }

  clearInputs() {
    const inputs = document.querySelectorAll(
      `#new-delivery-express-form input, #new-delivery-express-form select, #new-delivery-express-form textarea`,
    );

    inputs.forEach((input) => {
      if (input.name !== "customer_phone") {
        input.value = "";
      }
    });
  }

  getCustomerByPhone(event) {
    const update_button = document.querySelector("#update-customer-button");
    const register_button = document.querySelector("#create-customer-button");
    const customer_cep = document.querySelector("#customer_cep");
    const customer_number = document.querySelector("#customer_number");
    const customer_complement = document.querySelector("#customer_complement");
    const customer_full_name = document.querySelectorAll(
      "#first_name_destiny, #last_name_destiny",
    );
    const phone = event.target.value.replace(/\D/g, "");
    const url = `/administration/company/customers/find_customer_by_phone?phone=${phone}`;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    Swal.fire({
      title: "Buscando cliente",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.error) {
          this.clearInputs();
          register_button.removeAttribute("disabled");
          update_button.setAttribute("disabled", "true");
          customer_cep.removeAttribute("disabled");
          new Swal({
            icon: "error",
            text: data.error,
          });
          return;
        }
        Swal.fire({
          icon: "success",
        });
        current_customer.customer = data.customer;
        current_customer.address = data.address;
        current_customer.full_address = data.full_address;

        customer_cep.value = data.address.zipcode;
        customer_number.value = data.address.number;
        customer_complement.value = data.address.complement;
        customer_full_name[0].value = data.customer.first_name;
        customer_full_name[1].value = data.customer.last_name;

        update_button.removeAttribute("disabled");
        register_button.setAttribute("disabled", "true");
        customer_cep.removeAttribute("disabled");

        const event = { target: { value: data.address.zipcode } };
        this.bindCep(event);
        this.routeCard();
      })
      .catch((error) => {
        this.clearInputs();
        register_button.removeAttribute("disabled");
        update_button.setAttribute("disabled", "true");
        customer_cep.removeAttribute("disabled");
        new Swal({
          icon: "error",
          text: error,
        });
        return error;
      });
  }

  bindCep(event) {
    const cep = event.target.value.replace(/[\_.-]/g, "");
    const inputs = document.querySelectorAll(
      " #customer_state, #customer_city, #customer_neighborhood, #customer_street, #customer_number, #customer_complement",
    );

    const url = `https://viacep.com.br/ws/${cep}/json`;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    if (cep.length !== 8) {
      new Swal({
        text: "CEP inválido, verifique se os números estão corretos",
      });

      return;
    }

    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        inputs[0].value = data.uf;
        inputs[1].value = data.localidade;
        inputs[2].value = data.bairro;
        inputs[3].value = data.logradouro;

        inputs.forEach((input) => {
          input.removeAttribute("disabled");
        });
      })
      .catch((error) => {
        new Swal({
          text: "CEP inválido, verifique se os números estão corretos",
        });
      });
  }

  async getCityID(city_name, uf) {
    const url = "/administration/company/addresses/find_address_by_city?";
    const requestOptions = {
      method: "GET",
    };
    const params = `city=${city_name}&uf=${uf}`;

    await fetch(url + params, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        customer.city_id = resp.city_id;
      })
      .catch((error) => {});
  }

  async latLng(address) {
    const google_api_key = document.querySelector(".google-directions").id;
    const origin_url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${google_api_key}`;
    const requestOptions = {
      method: "GET",
    };

    await fetch(origin_url, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        customer.lat_lng = {
          lat: resp.results[0].geometry.location.lat,
          lng: resp.results[0].geometry.location.lng,
        };
      })
      .catch((error) => {});
  }

  routeCard() {
    const route_container = document.querySelector("#route-container");
    const destiny_route = document.querySelector("#destiny-route");

    route_container.classList.toggle("d-none");
    destiny_route.innerText = current_customer.full_address;
  }

  async showMap() {
    const map_element = document.querySelector("#map-container");
    const address_fields_element = document.querySelector("#address-fields");
    map_element.classList.toggle("d-none");
    address_fields_element.classList.toggle("d-none");

    const origin_address = document.querySelector(
      "#full-origin-address",
    ).textContent;

    const destiny_ltlng = {
      lat: Number(current_customer.address.lat),
      lng: Number(current_customer.address.lng),
    };

    const google_maps = new GoogleMaps();

    google_maps &&
      google_maps.routeExpress(
        origin_address,
        current_customer.full_address,
        destiny_ltlng,
      );
  }
}
