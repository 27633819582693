import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["header", "body", "footer", "loader"];

  connect() { }

  // Regarregar a página
  onLoading(event) {
    this.loaderTarget.classList.remove("d-none");
  }

  // Receber o conteúdo do backend e redenizar na tela
  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.bodyTarget.innerHTML = xhr.response;
    this.loaderTarget.classList.add("d-none");
  }

  // Fechar o modal presente na tela
  onCloseSuccess(event) {
    this.headerTarget.querySelector("button").click();
  }

  // Resetar o modal quando ele for aberto
  onBeforeOpen(event) {
    this.bodyTarget.innerHTML = "";

    this.headerTarget.className = "modal-header bg-secondary";
    this.headerTarget.querySelector("h5").innerHTML = event.params.title;

    if(!!document.querySelector('[id=modal-remote]>.modal-sm')) {
      const modal = document.querySelector('[id=modal-remote]>.modal-sm')
      modal.classList.remove('modal-sm')
      modal.classList.add('modal-xl')
    }
  }

  // Quanto todas as requisições terminarem
  onCompleteOpen(event) {
    const [status, xhr] = event.detail;
    const { backgroundColorHeader, title } = event.params;

    this.headerTarget.classList.remove("bg-secondary");
    this.headerTarget.classList.add(backgroundColorHeader);
  }

  resizeSmall(event) {
    const modal = document.querySelector('[id=modal-remote]>.modal-dialog')
    modal.classList.remove('modal-xl')
    modal.classList.add('modal-sm')
  }
}
