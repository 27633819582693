// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {}

  defineAdminCompany(event) {
    const company_id = event.target.options[event.target.selectedIndex].value;

    fetch("/administration/admin_users/define_company", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_id,
      }),
    }).then((resp) => {
      document.querySelector("#delivery").contentWindow.location.reload(true);
    });
  }
}
