import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import { formData } from "./deliveries_controller";

let polyline;

let originLatLng;
let destinationsLatLng;

let route_params = {
  origin: {},
  destination: {},
};

async function initMap() {
  const { GeometryLibrary } = await google.maps.importLibrary("geometry");
  const { Marker } = await google.maps.importLibrary("marker");

  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: 8,
    center: { lat: originLatLng.lat, lng: originLatLng.lng },
  });

  const path = new google.maps.geometry.encoding.decodePath(
    polyline.encodedPolyline,
  );

  const set_polyline = new google.maps.Polyline({
    path: path,
    map: map,
    geodesic: true,
    strokeColor: "#17a2b8",
    strokeOpacity: 1.0,
    strokeWeight: 3,
  });

  const marker_origin = new google.maps.Marker({
    map: map,
    draggable: false,
    title: `${formData.origin.origin_street} - ${formData.origin.origin_number}`,
  });

  marker_origin.setPosition(originLatLng);

  formData.destinations.forEach((destiny) => {
    const destinyLatLng = { lat: destiny.lat, lng: destiny.lng };
    const address = {};

    if (destiny.destiny_street) {
      address.street = destiny.destiny_street;
      address.number = destiny.destiny_number;
    } else {
      address.street = destiny.new_street;
      address.number = destiny.new_number;
    }

    if (originLatLng !== destinyLatLng) {
      const marker = new google.maps.Marker({
        map: map,
        draggable: false,
        position: destinyLatLng,
        title: `${address.street} - ${address.number}`,
      });

      marker.setPosition(destinyLatLng);
    }
  });

  set_polyline.setMap(map);

  const bounds = new google.maps.LatLngBounds();
  path.forEach(function (point) {
    bounds.extend(point);
  });

  map.fitBounds(bounds);
}

export default class GoogleMaps extends Controller {
  static targets = ["output"];

  connect() {}

  async route(
    origin,
    destinations,
    origin_return,
    travel_mode,
    routing_preference = "",
    optimize_waypoint_order,
  ) {
    route_params = {
      origin: {},
      destination: {},
      travelMode: travel_mode,
      routingPreference: routing_preference,
      optimizeWaypointOrder: optimize_waypoint_order,
    };
    const google_api_key = document.querySelector(".google-api-key").id;
    const destiny = origin_return ? destinations[1] : destinations[0];
    const origin_address = `${origin.origin_street}, ${origin.origin_number} - ${destiny.origin_complement || ''}, ${origin.origin_neighborhood},${origin.origin_city} ${origin.origin_state}, Brasil`;
    const destiny_address = `${destiny.destiny_street}, ${destiny.destiny_number} - ${destiny.destiny_complement || ''}, ${destiny.destiny_neighborhood},${destiny.destiny_city} ${destiny.destiny_state}, Brasil`;

    route_params.origin.address = origin_address;

    route_params.destination.address = origin_return
      ? origin_address
      : destiny_address;

    if (destinations.length > 1) {
      route_params["intermediates"] = [];
      destinations.forEach((new_destiny) => {
        if (new_destiny.new_cep) {
          const new_destiny_address = `${new_destiny.new_number} ${new_destiny.new_street} ${new_destiny.new_neighborhood},${new_destiny.new_city} ${new_destiny.new_state}, Brasil`;
          route_params.intermediates.push({ address: new_destiny_address });
        }
      });
    }

    origin_return &&
      route_params.intermediates.unshift({ address: destiny_address });

    const url = `https://routes.googleapis.com/directions/v2:computeRoutes?key=${google_api_key}`;

    const requestOptions = {
      method: "POST",
      headers: {
        "X-Goog-FieldMask": `routes.distanceMeters,routes.duration,routes.polyline.encodedPolyline,${
          optimize_waypoint_order
            ? "routes.optimized_intermediate_waypoint_index"
            : ""
        }`,
      },
      body: JSON.stringify(route_params),
    };

    this.latLng(origin_address, destinations, google_api_key);

    Swal.fire({
      title: "Gerando rota",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        this.distance(data.routes[0].distanceMeters);
        polyline = data.routes[0].polyline;
        formData.optimized_intermediate_waypoint_index =
          data.routes[0].optimizedIntermediateWaypointIndex;
        initMap();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Rota gerada com sucesso",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Falha ao gerar rota",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  async latLng(origin_address, destinations, google_api_key) {
    const origin_url = `https://maps.googleapis.com/maps/api/geocode/json?address=${origin_address}&key=${google_api_key}`;
    const requestOptions = {
      method: "GET",
    };

    await fetch(origin_url, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        formData.origin["lat"] = resp.results[0].geometry.location.lat;
        formData.origin["lng"] = resp.results[0].geometry.location.lng;

        originLatLng = resp.results[0].geometry.location;
      })
      .catch((error) => {});

    destinationsLatLng = [];

    destinations.forEach(async (destiny, index) => {
      const params = {};
      if (destiny.destiny_cep) {
        params.address = `${destiny.destiny_street}, ${destiny.destiny_number} - ${destiny.destiny_complement || ''}, ${destiny.destiny_neighborhood},${destiny.destiny_city} ${destiny.destiny_state}, Brasil`;
        params.url = `https://maps.googleapis.com/maps/api/geocode/json?address=${params.address}&key=${google_api_key}`;
      } else {
        params.address = `${destiny.new_street}, ${destiny.new_number}  - ${destiny.new_complement || ''}, ${destiny.new_neighborhood},${destiny.new_city} ${destiny.new_state}, Brasil`;
        params.url = `https://maps.googleapis.com/maps/api/geocode/json?address=${params.address}&key=${google_api_key}`;
      }
      await fetch(params.url, requestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
          formData.destinations[index]["lat"] =
            resp.results[0].geometry.location.lat;

          formData.destinations[index]["lng"] =
            resp.results[0].geometry.location.lng;

          destinationsLatLng.push(resp.results[0].geometry.location);
        })
        .catch((error) => {});
    });
  }

  distance(distance) {
    const distance_km = distance / 1000;
    const distance_km_display = distance_km.toFixed(2).replace(".", ",");
    const distance_display = document.querySelector("#distance_display");

    formData.total_distance = distance_km;

    distance_display.innerText = `${distance_km_display}km`;
    const total_value = document.querySelector("#total-value-display");
    const all_values = document.querySelector("#all-values-display");
    const url = "/administration/company/deliveries/values_preview.json";
    const params = {
      contract_id: formData.contract_id,
      total_distance: distance_km,
      vehicle_type: formData.vehicle_type,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        const value = parseFloat(data.net_price).toFixed(2).replace(".", ",");
        const initial_value = parseFloat(data.initial_modal)
          .toFixed(2)
          .replace(".", ",");
        const gross_value = parseFloat(data.gross_price)
          .toFixed(2)
          .replace(".", ",");
        const extra_fee = parseFloat(data.extra_fee)
          .toFixed(2)
          .replace(".", ",");

        total_value.innerHTML = `<h6><b>Valor total:</b> R$ ${value}</h6>`;
        all_values.innerHTML = `
                                  <span><b>Valor inicial:</b> R$ ${initial_value}</span> 
                                  <span><b>Valor extra de km:</b> R$ ${extra_fee}</span>  
                                  <span><b>Valor do entregador:</b> R$ ${gross_value}</span>
                                `;
      })
      .catch((_) => {});
  }

  async routeExpress(origin_address, destiny_address, latlng_destiny) {
    const google_api_key = document.querySelector(".google-directions").id;

    destinationsLatLng = [latlng_destiny];
    const origin_latlng_url = `https://maps.googleapis.com/maps/api/geocode/json?address=${origin_address}&key=${google_api_key}`;
    await fetch(origin_latlng_url, { method: "GET" })
      .then((resp) => resp.json())
      .then((resp) => {
        originLatLng = resp.results[0].geometry.location;
      })
      .catch((error) => {
        return error;
      });

    const route_params = {
      origin: { address: origin_address },
      destination: { address: destiny_address },
    };
    const url = `https://routes.googleapis.com/directions/v2:computeRoutes?key=${google_api_key}`;

    const requestOptions = {
      method: "POST",
      headers: {
        "X-Goog-FieldMask":
          "routes.distanceMeters,routes.duration,routes.polyline.encodedPolyline",
      },
      body: JSON.stringify(route_params),
    };

    await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        polyline = resp.routes[0].polyline;
        initMap();
      })
      .catch((error) => {
        return error;
      });
  }
}
