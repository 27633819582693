import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["error", "success", "info"];

  connect() { }

  errorTargetConnected(element) {
    const { title, message } = this.notificationAttributes(element);

    toastr.error(message, title);
    element.remove();
  }
  
  infoTargetConnected(element) {
    const { title, message } = this.notificationAttributes(element);

    toastr.info(message, title);
    element.remove();
  }

  successTargetConnected(element) {
    const { title, message } = this.notificationAttributes(element);

    toastr.success(message, title);
    element.remove();
  }

  notificationRemote(event) {
    const [data, status, xhr] = event.detail;

    const el = document.createElement('div')
    el.innerHTML = xhr.response
    document.querySelector('body').appendChild(el)
  }

  // private
  notificationAttributes(element) {
    return {
      title:
        element.content.querySelector("#ui-notification-title").innerHTML || "",
      message:
        element.content.querySelector("#ui-notification-message").innerHTML ||
        "",
    };
  }
}
