import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"];

  connect() { }

  changeStatus(event) {
    const status = event.target.value;

    const photoBox = document.querySelector('#photo_box')
    const completedStatusInputs = document.querySelectorAll(
      "#receiver_role_box, #responsible_for_receiving_box, #completed_at_box"
    );
    const failureReasonInput = document.querySelector("#failure_reason_box");

    photoBox.classList.add('d-none')
    failureReasonInput.classList.add("d-none");
    completedStatusInputs.forEach((input) => { input.classList.add("d-none"); });

    if (status === 'completed' || status === 'failed') {
      photoBox.classList.remove('d-none')
    }

    if (status === "completed") {
      completedStatusInputs.forEach((input) => {
        input.classList.remove("d-none");
      });
      return;
    }

    if (status === "failed") {
      failureReasonInput.classList.remove("d-none");
      return;
    }
  }
}
