import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['zipcode', 'street', 'number', 'complement', 'neighborhood', 'city', 'state']

  connect() {
    this.toggleAddressEdit()

    if(this.stateTarget.value !== '' && this.cityTarget.value === '' && this.zipcodeTarget.value !== '') {
      this.findCep({ target: this.zipcodeTarget })
    }
  }

  findCep(event) {
    document.querySelector('.modal .overlay').classList.remove('d-none')
    this.toggleAddressEdit(true)

    if(this.zipcodeTarget.value === '') {
      document.querySelector('.modal .overlay').classList.add('d-none')
      toastr.info('CEP precisa ser informado', 'Endereço')
      return
    }

    fetch(`/administration/addresses/${event.target.value}.json?type=cep`)
      .then(data => data.json())
      .then(response => {
        const { address } = response

        this.zipcodeTarget.value  = address.zipcode
        this.streetTarget.value = address.street
        this.neighborhoodTarget.value = address.neighborhood
        this.stateTarget.value = address.city.state.id

        this.findCity(address.city.state.id, address.city.id)
      })
      .catch( error => {
        toastr.error('Endereço não encontrado', 'Endereço')
        this.zipcodeTarget.value = ''
        this.zipcodeTarget.focus()
        document.querySelector('.modal .overlay').classList.add('d-none')
      })
      .finally( () => {
        this.toggleAddressEdit()
        if(this.cityTarget.value !== '') document.querySelector('.modal .overlay').classList.add('d-none')
      })
  }

  findSelectCity(event) {
    document.querySelector('.modal .overlay').classList.remove('d-none')
    this.findCity(event.target.value)
  }
  
  // Privados

  findCity(stateId, cityId = null) {
    this.cityTarget.setAttribute("disabled", true);
    this.cityTarget.innerHTML = ''

    fetch(`/administration/cities.json?q[state_id_eq]=${stateId}`)
      .then((data) => data.json())
      .then((response) => {
        const { cities } = response

        cities.forEach((d) => {
          let element = document.createElement("option");
          element.value = d.id;
          element.innerHTML = d.name;
          this.cityTarget.appendChild(element);
        });

        if(cityId !== null) this.cityTarget.value = cityId 
      })
      .finally(() => {
        this.cityTarget.removeAttribute("disabled")
        document.querySelector('.modal .overlay').classList.add('d-none')
      });
  }

  toggleAddressEdit(resetFields = false) {
    if(this.zipcodeTarget.value === '' || resetFields) {
      this.streetTarget.setAttribute('disabled', true)
      this.streetTarget.value = ''
      this.numberTarget.setAttribute('disabled', true)
      this.numberTarget.value = ''
      this.complementTarget.setAttribute('disabled', true)
      this.complementTarget.value = ''
      this.neighborhoodTarget.setAttribute('disabled', true)
      this.neighborhoodTarget.value = ''
      this.cityTarget.setAttribute('disabled', true)
      this.cityTarget.value = ''
      this.stateTarget.setAttribute('disabled', true)
      this.stateTarget.value = ''
    } else {
      this.streetTarget.removeAttribute('disabled')
      this.numberTarget.removeAttribute('disabled')
      this.complementTarget.removeAttribute('disabled')
      this.neighborhoodTarget.removeAttribute('disabled')
      if(this.cityTarget.value !== '') this.cityTarget.removeAttribute('disabled')
      this.stateTarget.removeAttribute('disabled')
    }
  }
}