import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  clickCheckbox(event) {
    const inputCheckbox = event.currentTarget.querySelector('input[type="checkbox"]')
    inputCheckbox.click()

    this.setRowSelect(inputCheckbox)
  }

  setRowSelect(elementInputChecked) {
    if (elementInputChecked.checked) {
      elementInputChecked.closest('tr').classList.add('row-select')
    } else {
      elementInputChecked.closest('tr').classList.remove('row-select')
    }
  }
}
