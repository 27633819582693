import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tableContainer", "loaderContainer"];

  connect() { }

  // Chamar antes de uma requisição
  onTableLoad(event) {
    this.loaderContainerTarget.classList.remove("d-none");
    document.querySelector("html").classList.add("loader-overflow");
  }

  // Chamar quando receber uma requisição com sucesso
  onTableSuccess(event) {
    const [data, status, xhr] = event.detail;

    this.loaderContainerTarget.classList.add("d-none");
    document.querySelector("html").classList.remove("loader-overflow");
    this.tableContainerTarget.innerHTML = xhr.response;
  }

  onNotification(event) {
    const [data, status, xhr] = event.detail;

    const el = document.createElement('div')
    el.innerHTML = xhr.response
    this.tableContainerTarget.appendChild(el)
  }

  onRemoveRow(event) {
    const { redirectPath } = event.params;

    Turbolinks.visit(redirectPath)
  }
}
