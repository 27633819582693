import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2";

const export_report_button = document.querySelector("#export-report");
const send_report_button = document.querySelector("#send-report");
const all_checked_button = document.querySelector("#select_all");

export default class extends Controller {
  static targets = ["exportType"];

  connect() { }

  customReport(event) {
    const filter = document.querySelector("#filter");

    if (event.target.value === "custom") {
      filter.classList.remove("d-none");
      return;
    }

    filter.classList.add("d-none");
  }

  allChecked() {
    let all_checkboxes = document.querySelectorAll(".filter_checkbox");

    all_checkboxes.forEach((checkbox) => {
      checkbox.checked ? (checkbox.checked = false) : (checkbox.checked = true);
    });
  }

  defineFormatResponse(event) {
    if (this.exportTypeTarget.value === "download") {
      let [data, status, xhr] = event.detail;

      const csvContent = `data:text/csv;charset=utf-8,${data}`;
      const encodedUri = encodeURI(csvContent);
      const current_date_format = new Date()
        .toLocaleString()
        .replaceAll("/", "")
        .replace(" ", "")
        .replace(",", "")
        .replaceAll(":", "");
      const filename = `relatorios-entregas-${current_date_format}`;

      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } else {
      document.querySelector('.loader-container').classList.add('d-none')
      toastr.success('Aguarde enquanto o Relatório na fila é processado e enviado por email', "Relatório");
    }

  }

  notificateReport(element) {
    if (this.exportTypeTarget.value === "email") {
      document.querySelector('.loader-container').classList.remove('d-none')
      return
    }

    Swal.fire({
      title: element.params.currentAdminUser,
      text: 'Fique na página até que o download seja concluido',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#007bff'
    })
  }

  permitedDownload(event) {
    toastr.error("Espere uns minutos para Extração de Relatorio", "Relatório Entregador");
  }
}
