import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['groupSelect', 'companySelect', 'roleSelect']

  roleSelectTargetConnected() {
    this.stateSelectRole({ skipNotification: true })
  }

  handleCategories(event) {
    this.roleSelectTarget.setAttribute("disabled", "true");

    this.groupSelectTarget.innerHTML = "";
    this.groupSelectTarget.setAttribute("disabled", "true");
    this.stateSelectRole({ skipNotification: false })

    // Fazer a requsição
    fetch(
      `/administration/admin_user_categories.json?q[visible_role_eq]=${this.findRoleId(this.roleSelectTarget.value)}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        data.forEach((d) => {
          let element = document.createElement("option");
          element.value = d.id;
          element.innerHTML = d.name;
          this.groupSelectTarget.appendChild(element);
        });
      })
      .finally(() => {
        event.target.removeAttribute("disabled");
        this.groupSelectTarget.removeAttribute("disabled");
      });
  }

  // privados

  stateSelectRole({ skipNotification }) {
    if (this.roleSelectTarget.value == "") {
      this.roleSelectTarget.removeAttribute("disabled");
      if (!skipNotification) toastr.info("Precisa selecionar um tipo de usuário");
    }
  }

  findRoleId(roleEnum) {
    const listRoles = {
      "admin": 0,
      "employee": 1,
      "company": 2
    }

    return listRoles[roleEnum]
  }
}
