import { Controller } from "@hotwired/stimulus"
import "chosen-js";

export default class extends Controller {
  static targets = ["chosenSelect"];
  static values = { width: String };

  chosenSelectTargetConnected(element) {
    element.classList.add('chosen-select')
    $(".chosen-select").chosen();

    if (this.hasWidthValue) {
      document.querySelector(".chosen-container-single").style.width =
        this.widthValue;
    }
  }
}
